export const pageHeaderProps = `
headerPresetTitle
rightTintColor
leftTintColor
whiteLogo
messageBackgroundColor { colorArray }
messageTextColor { colorArray }
messageDisplayRule
message
sticky`

export type PageHeaderProps = {
  headerPresetTitle?: string
  rightTintColor?: string | string[]
  leftTintColor?: string | string[]
  whiteLogo?: (boolean | 'Yes' | 'No')[]
  messageBackgroundColor?: {
    colorArray?: string[]
  }
  messageDisplayRule?: "Both" | "Desktop" | "Mobile";
  messageTextColor?: {
    colorArray?: string[]
  }
  message?: string
  sticky?: boolean
}
